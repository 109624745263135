<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-3 shadow mb-3 bg-white rounded">
        <div class="row m-0 p-0">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Attribute Products</p>
          </div>
        </div>
        <!-- ---------------------------- -->
        <div class="row m-0 px-3">
          <!-- --------------------- -->
          <div class="col-12 col-xl-6 mb-2">
            <div class="form-group row">
              <label>CatCode</label>
              <div class="col-sm-10">
                <div class="input-group input-group-sm m-0 p-0 ">
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.cattext"
                    @click="callModalcatcode()"
                  />
                  <div class="input-group-append">
                    <button
                      class="input-group-text px-3"
                      @click="Clearcatcode()"
                    >
                      x
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ------------------------------- -->
          <div class="col-12 col-xl-6 mb-2">
            <div class="input-group input-group-sm ">
              <div class="input-group-prepend input-group-sm">
                <select class="custom-select" v-model="data.mode">
                  <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                    {{ e.name }}
                  </option>
                </select>
              </div>

              <input
                type="search"
                class="form-control"
                placeholder="ค้นหา / Seach"
                v-model="data.find"
              />
            </div>
          </div>
          <!-- --------------------------------------------- -->
          <div class="col-12 col-xl-6  mb-3">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="notmapping"
                value="1"
                v-model="data.notmapping"
              />
              <label class="form-check-label" for="notmapping">
                สินค้าที่ยังไม่ระบุค่าแอตทริบิวต์
              </label>
            </div>
          </div>
        </div>
        <div class="form-check">
          <!-- ------------------------------- -->
          <div class="col-12 mb-3">
            <button
              type="button"
              class="btn bt-main btn-sm mx-1 px-4"
              @click="confirm()"
            >
              <span class="mx-1" v-html="$svg_icon.search"></span>ค้นหา
            </button>
            <button
              type="button"
              class="btn bt-main1 btn-sm mx-1 px-4"
              @click="redata()"
            >
              <span class="mx-1" v-html="$svg_icon.reset"></span>รีเซ็ต
            </button>
          </div>
        </div>
      </div>
      <!-- --------------------------table ------------------ -->
      <div class="col-12 m-0 p-3 shadow mb-3 bg-white rounded">
        <p class="px-3 font-600 w-100" style="display: flex">
          สินค้า&nbsp;
          <span class="text-main">{{
            new Intl.NumberFormat().format(totalRows)
          }}</span>
          &nbsp;รายการ
        </p>
        <div class="col-12">
          <b-table
            :empty-text="'ไม่พบข้อมูล / No Data'"
            :fields="fields"
            :items="items"
            :current-page="page_num"
            :per-page="page_size"
            outlined
            hover
            show-empty
            responsive
            class="font-0-8s"
          >
            <template #cell(mapAttr)="row">
              <span
                :class="[row.item.mapAttr == 'Y' ? 'badge-main' : 'badge-twd']"
                class="badge"
                >{{ row.item.mapAttr == "N" ? "No" : "Yes" }}</span
              >
            </template>
            <template #cell(showOnWeb)="row">
              <p
                class="badge badge-pill font-500 badge-twd m-0 py-1"
                v-if="String(row.item.showOnWeb).trim() == 'TWD'"
              >
                {{ row.item.showOnWeb }}
              </p>
              <p
                class="badge badge-pill font-500 badge-bnb m-0 py-1"
                v-else-if="String(row.item.showOnWeb).trim() == 'BNB'"
              >
                {{ row.item.showOnWeb }}
              </p>
              <p
                class="badge badge-pill font-500 badge-main m-0 py-1"
                v-else-if="String(row.item.showOnWeb).trim() == 'BNB|TWD'"
              >
                {{ row.item.showOnWeb }}
              </p>
              <p v-else>
                {{ row.item.showOnWeb }}
              </p>
            </template>
            <template #cell(action)="row">
              <span
                type="button"
                class="text-muted mx-2"
                v-html="$svg_icon.edit"
                @click="edit(row.item)"
              ></span>
            </template>
          </b-table>
        </div>
        <div class="col-12">
          <div class="row justify-content-end">
            <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
              <select class="custom-select" v-model="page_size">
                <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                  {{ e }}
                </option>
              </select>
            </div>
            <div class="col-6 col-xl-3">
              <b-pagination
                v-model="page_num"
                :total-rows="totalRows"
                :per-page="page_size"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modalcatcode
      :key="reModalcatcode"
      confirmlvl="3"
      :init="true"
      :saveStroe="true"
      @return_data="return_data"
    />
  </div>
</template>

<script>
import Modalcatcode from "@/components/common/Modalcatcode.vue";
export default {
  name: "AttributesProductsList",
  components: { Modalcatcode },
  data() {
    return {
      init: true,
      reModalcatcode: Date.now() + 5,
      data: {
        mode: "skucode",
        find: "",
        cattext: "",
        catCode: "",
        notmapping: 0,
      },
      select_mode: [
        { name: "ค้นหาด้วยเลข SKCode", key: "skucode" },
        { name: "ค้นหาด้วย prNameTH", key: "name" },
      ],

      totalRows: 0,

      items: [],

      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      fields: [
        { key: "skCode", label: "skCode" },
        // { key: "catCode", label: "catCode" },

        { key: "prNameTH", label: "prNameTH" },
        { key: "mapAttr", label: "AttributeValue", class: "text-center" },
        { key: "showOnWeb", label: "showOnWeb", class: "text-center" },

        {
          key: "enrich",
          label: "enrich",
          class: "text-center",
          thStyle: { width: "8%" },
        },
        {
          key: "action",
          label: "",
          class: "text-center",
          thStyle: { width: "8%" },
        },
      ],
    };
  },
  watch: {
    com_get_category_web: function(v) {
      if (this.items.length === 0) {
        this.confirm();
      }
    },
    "data.notmapping": function(v) {
      console.log(v);
      if (v) {
        this.data.notmapping = 1;
      } else {
        this.data.notmapping = 0;
      }
    },
  },
  computed: {
    com_get_category_web: function() {
      return this.$store.getters["categoryActions/get_category_web"];
    },
  },
  async mounted() {
    this.confirm();
  },
  methods: {
    edit(e) {
      this.$router.push({
        name: "Attributes-Products-Detail",
        params: { skcode: e.skCode },
      });
    },
    async confirm() {
      try {
        let cat = this.$store.getters["categoryActions/get_category_web"];

        this.data.cattext = cat?.selectnow;
        this.data.catCode = cat?.catcode;
        let { catCode, find, notmapping, mode } = this.data;
        let data = {
          mode: mode,
          find: find,
          catCode: catCode,
          notmapping: notmapping,
        };

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `attribute/get_attribute_porduct_list`,
          data,
          1
        );
        //  console.log(getAPI.data);
        this.items = getAPI.data;
        this.totalRows = this.items.length;
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    redata() {
      this.data = {
        mode: "skucode",
        find: "",
        cattext: "",
        catCode: "",

        notmapping: 0,
      };

      this.$store.dispatch("categoryActions/resetcategoryweb");
    },
    return_data(data) {
      let { catcode, selectnow } = data;
      this.data.cattext = selectnow;
      this.data.catCode = catcode;
    },

    callModalcatcode() {
      this.reModalcatcode = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modalcatcode");
      }, 100);
    },
    // return_data(data) {
    //   let { catcode, selectnow } = data;
    //   this.data.cattext = selectnow;
    //   this.data.catCode = catcode;
    // },
    Clearcatcode() {
      this.data.cattext = "";
      this.data.catCode = "";

      this.$store.dispatch("categoryActions/resetcategoryweb");
    },
  },
};
</script>

<style></style>
